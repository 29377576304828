import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Addons are external functions that control or create many different Tippy
instances, and can be tree-shaken away by bundlers.`}</p>
    <h3>{`Event delegation`}</h3>
    <p>{`Event delegation allows you to let a common parent element handle the creation
of tippy instances for child elements.`}</p>
    <p>{`This allows two things:`}</p>
    <ul>
      <li parentName="ul">{`It prevents the need to create new instances for new child elements appended
to the parent.`}</li>
      <li parentName="ul">{`It improves performance as the creation of the tippy instances is deferred
until they are triggered for the first time.`}</li>
    </ul>
    <h4>{`Usage`}</h4>
    <p>{`Your markup should have a structure like this example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`child`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Text`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`child`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Text`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`child`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Text`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Pass a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`targets`}</code>{` argument to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`delegate()`}</code>{` addon function (the same type the
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy()`}</code>{` function can accept) which represents the parent element(s) that
should act as a delegate, and a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`target`}</code>{` prop representing a CSS selector that
should match the child elements which should receive a tippy.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`delegate`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`delegate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`#parent`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  target`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`.child`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`In the CDN (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iife`}</code>{`) version, it's available as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy.delegate()`}</code></p>
    <h4>{`Return type`}</h4>
    <p>{`Because `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`delegate()`}</code>{` can create many different instances, it returns an opaque
value depending on the type supplied, just like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy()`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`delegateInstances`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`delegate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`.parent`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  target`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`.child`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Instance[]`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`delegateInstance`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`delegate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`parentElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  target`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`.child`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Instance`}</span></code></pre></div>
    <h4>{`Cleanup`}</h4>
    <p>{`By default, when you destroy a delegate instance, it also destroys any child
instances that may have been created by it. If you want to prevent this
behavior, pass `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{` as an argument:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`delegateInstance`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`delegate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`parentElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  target`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`.child`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Prevents further creation and destroys any created child tippy instances`}</span>{`
`}<span parentName="code" {...{
            "className": "token object"
          }}>{`delegateInstance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`destroy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Prevents further creation only`}</span>{`
`}<span parentName="code" {...{
            "className": "token object"
          }}>{`delegateInstance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`destroy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`Polyfill`}</h4>
    <p>{`This addon uses `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Element.prototype.closest()`}</code>{`, which is not supported in older
browsers. You will need to polyfill this method to get full support.`}</p>
    <hr></hr>
    <h3>{`Singleton`}</h3>
    <p>{`A singleton is a single tippy element that takes the place of an array of
regular tippy instances.`}</p>
    <p>{`This allows two things:`}</p>
    <ul>
      <li parentName="ul">{`Smooth transitions of the tippy between many different reference element
targets`}</li>
      <li parentName="ul">{`Elements with tooltips next to each other that have a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`delay`}</code>{` can be "grouped"
so they appear to share a timeout, which greatly improves UX`}</li>
    </ul>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/#singleton"
      }}>{`demo`}</a>{` for it in action.`}</p>
    <h4>{`Usage`}</h4>
    <p>{`Pass an `}<strong parentName="p">{`array`}</strong>{` of tippy instances to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createSingleton`}</code>{` addon function,
and a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`delay`}</code>{` prop:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` tippy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`createSingleton`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`tippyInstances`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`singleton`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createSingleton`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`tippyInstances`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`delay`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`In the CDN (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iife`}</code>{`) version, it's available as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy.createSingleton()`}</code></p>
    <h4>{`Smooth transitions`}</h4>
    <p>{`Utilize the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`updateDuration`}</code>{` prop, which is the transition duration between
position updates of the tippy element:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`singleton`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createSingleton`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`tippyInstances`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  delay`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  updateDuration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong></p>
      <p parentName="blockquote">{`Enabling transitions may cause overflow issues if the tippy content is near
the right/bottom edge of a boundary (e.g. window) and the tippy content
changes from small to large. This is because of the transition causing the
tippy to expand the boundary, making Popper.js think it will fit.`}</p>
    </blockquote>
    <p>{`To solve this, constrain the tippy to the viewport:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`createSingleton`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`instances`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  boundary`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`viewport`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`Timing function`}</h4>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition-timing-function`}</code>{` by default is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`easeOutQuint`}</code>{`. Usually this
looks nice, but you can change this (to add an inertial slingshot effect for
example) like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`singleton`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createSingleton`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`tippyInstances`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  updateDuration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`onCreate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token block"
            }}>{`{`}</span>{`popper`}<span parentName="span" {...{
              "className": "token block"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Any easing function you want.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token object"
          }}>{`popper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`transitionTimingFunction`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`cubic-bezier(...)`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      